<template>
	<div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-7 col-md-8 col-xl-10 mb-2 px-1">
                     <label><i class="far fa-star color-theme font-12 me-1"></i> Vip</label>
                     <v-select :options="listaVips" v-model="pesquisa.vip" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-5 col-md-4 col-xl-2 mb-2 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchUsers">
                        <i class="fas fa-search"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-if="resultado.length == 0">Nenhum jogador encontrado</div>

      <div class="col-12 p-0" v-else>
         <div class="row m-0">
            <jogador v-for="(jogador, index) in resultado" :key="index" :jogador="jogador" :index="index" @getInfoUser="getInfoUser($event)" />
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import jogador from '@/components/admin/vips/Jogador.vue'

export default {
	name: 'AdmVips',
   data: function () {
      return {
         pesquisa: {'vip': null},
         resultado: []
      }
   },
   computed: {
      ... mapState({
         listaVips: state => state.listaVips,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      jogador
   },
   methods: {
      searchUsers : function () {
         if (this.pesquisa.vip == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma opção!'
            });

            return
         }

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/searchUsers',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: 'Permissão',
               valor: this.pesquisa.vip
            }
         }).then(response => {
				this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      this.$store.dispatch('buscarVips')
   }
}

</script>