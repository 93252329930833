<template>
   <div class="col-12 p-0">
      <div class="card mb-1" :class="index % 2 == 0 ? '' : 'invert'">
         <div class="card-body p-3">
            <div class="row m-0 font-13">
               <div class="col-12 p-0 text-center text-md-start mb-2">
                  <strong class="limitador font-16">{{ jogador.nome != null ? jogador.nome : 'Desconhecido' }}</strong>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                  <div class="limitador">
                     <i class="far fa-hashtag color-theme font-11 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Identidade:</small> {{ jogador.id != null ? jogador.id : '-' }} 
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                  <div class="limitador">
                     <i class="fab fa-steam color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Steam:</small> {{ jogador.steam != null ? String(jogador.steam).replace('steam:', '') : '-' }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                  <div class="limitador">
                     <i class="far fa-money-bill-alt color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Banco:</small> {{ jogador.bank != null ? jogador.bank : '-' }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                  <div class="limitador">
                     <i class="far fa-coins color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Coins:</small> {{ jogador.coins != null ? jogador.coins : '-' }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                  <div class="limitador">
                     <i class="far fa-users color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Personagens:</small> {{ jogador.chars != null ? jogador.chars : '-'  }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                  <div class="limitador">
                     <i class="far fa-warehouse color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline text-secondary">Garagem:</small> {{ jogador.garage != null ? jogador.garage : '-' }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                  <div class="limitador">
                     <i class="far fa-star color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline">Vip:</small> {{ JSON.parse(jogador.vip) == true ? jogador.vipNome : '-' }}
                  </div>
               </div>
               <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                  <div class="limitador" v-if="JSON.parse(jogador.vip) == true">
                     <i class="far fa-calendar color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline">Expiração:</small> {{ new Date(parseInt(jogador.vipExpiracao) * 1000).toLocaleDateString('pt-BR') }}
                  </div>
                  <div class="limitador" v-else>
                     <i class="far fa-calendar color-theme font-10 me-1"></i>
                     <small class="d-none d-sm-inline">Expiração:</small> -
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Jogador',
   props: ['jogador', 'index']
}

</script>